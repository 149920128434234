<template>
<section class="figcaption">
     <div class="page-header">
        <h3 class="page-title">
        {{sectionTitle}}
        </h3>
    </div>
    <div class="row portfolio-grid">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"  v-for="(item, itemID) in items" :key="itemID">
            <figure class="effect-text-in">
                <img src="@/assets/images/samples/300x300/1.jpg" alt="image" />
                <figcaption>
                <h4>{{item[title]}}</h4>
                <p>{{item[description]}} + {{item[price]}}</p>
                </figcaption>
            </figure>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios'
export default {
    name: 'figcaption',
    data () {
        return {
            items: []
        }
    },
    props: {
        title: String,
        description: String,
        price: Number,
        entitytype: String,
        sectionTitle: String
    },
    methods: {
        getEntitytype () {
            axios.get(`https://sites.comolapapaya.com/${this.entitytype}`)
            .then ( resEntitytype => {
                this.items= resEntitytype.data.result
            })
            .catch ( errEntitytype => {console.log(errEntitytype)})
        }
    },
    mounted () {
        this.getEntitytype ()
    }
}
</script>